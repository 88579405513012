//////////////////////////////
// Heroes
//////////////////////////////

@mixin hero-variant($background-color, $color) {
    background-color: $background-color;
    color: $color;

    @include hero-title-breadcrumb-variant($background-color);
}

@mixin hero-gradient-variant($primary-color, $secondary-color, $text-color) {
    background: linear-gradient(-12deg, $secondary-color, $primary-color 80%);
    color: $text-color;

    @include hero-title-breadcrumb-variant($primary-color);
}

@mixin hero-title-breadcrumb-variant($color, $shiftDefault: 20%, $shiftHover: 40%) {
    $default: saturate(lighten($color, $shiftDefault), $shiftDefault);
    $hover: saturate(lighten($color, $shiftHover), $shiftHover);

    .hero-title-breadcrumb {
        > a {
            @include link-variant($default, $hover, none);

            &:after {
                color: $default;
            }
        }
    }

    .panel-hero {
        background: rgba($white, 0.2);

        small, .small {
            color: $hover;
        }

        hr {
            border-color: $hover;
        }
    }
}
