.hero-accounts {
    @include hero-variant($blue-grey-800, $white)

    hr {
        border-color: $blue-grey-900;
    }

    .panel-inverse {
        background: $blue-grey-900;
        box-shadow: none;

        hr {
            border-color: $blue-grey-800;
            border-style: dashed;
        }
    }
}
