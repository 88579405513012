//////////////////////////////
// Type
//////////////////////////////

@mixin link-variant($color, $hover-color, $decoration) {
    & {
        color: $color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $hover-color;
            text-decoration: $decoration;
        }

        &:focus {
            @include tab-focus;
        }
    }
}
